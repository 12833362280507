.team-route {
  align-items: center;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 96px 16px;
  width: 100%;

  &::before {
    background: #daccbc;
    content: '';
    display: block;
    height: 512px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    top: 0;
    width: 100%;
    z-index: -1;
  }

  h2 {
    color: #000000;
    font-size: 2em;
    margin: 0 auto;
    padding: 32px 16px;
    text-align: center;
  }

  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin: 0 auto;
    padding: 32px 16px;
    max-width: 1024px;

    @media (min-width: 1024px) {
      flex-direction: row;
    }
  }
}

.person-component {
  box-sizing: border-box;
  flex: 1 1 362px;
  height: 362px;
  position: relative;
  width: 432px;

  @media (min-width: 1024px) {
    flex: 1 1 176px;
    height: 432px;

    &::before {
      height: 352px;
    }
  }

  &::before {
    background: #ffffff;
    border-radius: 8px;
    bottom: 0;
    box-shadow: rgba(0,0,0,0.16) 0 0px 2px 1px;
    content: '';
    display: block;
    height: 282px;
    position: absolute;
    width: 100%;
    z-index: -1;

  }

  .image {
    border: 1px solid #efefef;
    border-radius: 50%;
    box-shadow: rgba(0,0,0,0.16) 0 4px;
    display: block;
    height: 160px;
    margin: 0 auto;
    object-fit: cover;
    width: 160px;
  }

  .content {
    padding: 16px;
    .name {
      margin: 16px 0 0;
      text-align: center;
    }

    .title {
      margin: 4px 0 0;
      text-align: center;
    }

    .about {
    }
  }
}
