.contact-route {
  padding: 128px 16px 0;

  .container {
    background: #ffffff;
    border-radius: 16px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 32px;
    width: 100%;
    max-width: 1024px;
  }

  h2 {
    margin: 0 0 32px;
  }

  form {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    margin: 0 auto;
    max-width: 640px;
  }

  input, textarea {
    background: #efefef;
    border: none;
    box-sizing: border-box;
    font-size: 1.15em;
    padding: 16px;
    width: 100%;
  }

  textarea {
    height: 256px;
  }

  button {
    border: none;
    background: #ff8a00;
    color: #ffffff;
    font-size: 1.25em;
    font-weight: 700;
    height: 48px;
    text-transform: uppercase;
    align-self: flex-end;
    width: 256px;
  }
}
