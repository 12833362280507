.story-route {
  padding: 96px 16px 0;

  .container {
    background: #ffffff;
    border-radius: 16px;
    box-sizing: border-box;
    margin: 32px auto;
    padding: 32px;
    width: 100%;
    max-width: 1024px;
  }

  h2 {
    margin: 0;
  }
  p {
    font-size: 1.25em;
  }
}

//.story-route {
//  padding: 96px 16px 0;
//
//  & > .container {
//    align-items: center;
//    box-sizing: border-box;
//    display: grid;
//    gap: 32px;
//    grid-template-columns: repeat(2, auto);
//    justify-content: center;
//    margin: 0 auto;
//    padding: 16px;
//    max-width: 1024px;
//  }
//}
//
//.story-item {
//  background: #ffffff;
//  border: 1px solid #dfdfdf;
//  box-shadow: rgba(0,0,0,0.16) 0 4px;
//  border-radius: 4px;
//  overflow: hidden;
//
//
//  img {
//    width: 100%;
//  }
//
//  .content {
//    padding: 16px;
//
//    h3 {
//      margin: 0;
//      padding: 0;
//    }
//
//    p {
//      margin: 0;
//      padding: 8px 0 16px;
//    }
//
//    button {
//      background: #6fa27a;
//      border: none;
//      color: #ffffff;
//      font-weight: bold;
//      padding: 8px 16px;
//      text-transform: uppercase;
//      width: 100%;
//    }
//  }
//}
