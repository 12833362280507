.about-route {
  padding: 96px 16px 0;

  .container {
    background: #ffffff;
    border-radius: 16px;
    box-sizing: border-box;
    margin: 32px auto;
    padding: 32px;
    width: 100%;
    max-width: 1024px;
  }

  h2 {
    margin: 0;
  }
  p {
    font-size: 1.25em;
  }
}
