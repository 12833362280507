.what-we-do-route {
  box-sizing: border-box;
  padding: 128px 32px 0;
  width: 100%;

  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1024px;
  }

  p {
    font-size: 1.25em;
  }

  img {
    width: 100%;
  }

}
