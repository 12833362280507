.navigation-component {
  box-sizing: border-box;
  color: #1e2e2e;
  height: 80px;
  padding: 8px;
  position: fixed;
  transition: box-shadow 240ms ease 240ms;
  width: 100%;
  z-index: 9;

  &::before {
    background: #ffffff;
    //box-shadow: rgba(0, 0, 0, 0.16) 0px 4px;
    content: '';
    display: block;
    left: 0;
    height: 100%;
    position: absolute;
    top: -96px;
    transition: top 0.32s ease-out;
    width: 100%;
    z-index: -1;
  }

  .logo {
    height: 64px;
    width: 64px;
  }

  .container {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 16px;
    max-width: 1024px;
  }

  .menu {
    button {
      background: none;
      border: none;
      color: inherit;
      font-size: 3em;
      padding: 0;
    }

    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  .links {
    background: #ffffff;
    border-bottom: 1px solid rgba(0,0,0,0.16);
    display: flex;
    flex-direction: column;
    gap: 16px;
    left: 0;
    padding: 160px 0 16px;
    position: absolute;
    top: -64px;
    transform: translateY(-100%);
    transition: transform 320ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
    width: 100%;
    z-index: -2;

    @media screen and (min-width: 1024px) {
      background: none;
      border: none;
      flex-direction: row;
      padding: 0;
      position: static;
      transform: none;
      width: auto;
      z-index: 0;
    }
  }

  &.menu-active {
    .links {
      transform: translateY(0);
    }

    &::before {
    }
  }

  .link {
    color: #000000;
    cursor: pointer;
    font-size: 1.25em;
    font-weight: 600;
    padding: 16px 32px;
    text-transform: uppercase;
  }

  @media (min-width: 1024px) {
    .link {
      border-radius: 4px;
      color: inherit;
      padding: 8px 16px 0px;
      transition: background 0.24s ease-out, color 0.24s ease-in;

      &::after {
        background: #1e2e2e;
        border-radius: 8px;
        content: '';
        display: block;
        height: 4px;
        margin: 8px 0 0;
        transition: width 0.24s ease-in-out;
        width: 0;
      }
      &:hover::after, &.active::after {
        width: 100%;
      }

      &.active {
        background: rgba(0, 0, 0, 0.16);
      }
    }
  }

  // Background light color (animation scroll from top):
  &.bg-light {
    //box-shadow: rgba(0, 0, 0, 0.16) 0px 4px;
    &::before {
      top: 0;
      border-bottom: 1px solid rgba(0,0,0,0.16);
    }
  }

  // Text and border light color:
  &.fg-light {
    color: #ffffff;
    .link {
      &::after { background: #ffffff; }
    }
  }

}
