.home-route {
  width: 100%;

  .banner {
    width: 100%;

    &::before {
      background: #daccbc;
      content: '';
      display: block;
      height: 75vw;
      max-height: 640px;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }

    .banner-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 96px 16px 0;

      h1 {
        color: #000000;
        &::first-letter {
          font-size: 1.25em;
        }
      }

      img {
        border-radius: 32px;
        position: relative;
        max-width: 640px;
        width: 100%;

        &::after {
          background: rgba(0,0,0,0.32);
          bottom: -32px;
          border-radius: 50%;
          content: '';
          display: block;
          height: 256px;
          position: absolute;
          width: 768px;
        }
      }

      .banner-shadow {
        background: rgba(0,0,0,0.32);
        border-radius: 50%;
        filter: blur(32px);
        height: 64px;
        width: 768px;
        z-index: -1;
      }
    }
  }

}

